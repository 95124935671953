<template>
  <div class="components-content">
    <div class="contact-search">
      <van-search v-model="value" show-action placeholder="请输入搜索关键词" @search="onSearch">
        <template #action>
          <div class="search" @click="onSearch">搜索</div>
        </template>
      </van-search>
    </div>
    <div class="member-list">
      <div v-for="(item, index) in members" :key="index" style="width:100%;">
        <div class="member-list-one" v-if="!item.watch">
          <div class="member-one-title">
            {{ item.name }}
          </div>
          <div v-for="(x, y) in item.child" :key="y">
            <div class="member-industry" v-if="x.watch">
              <div class="member-industry-name">
                <div class="img"></div>
                {{ x.name }}
              </div>
              <div class="member">
                <div class="member-one" v-for="(i, j) in x.member_list" :key="j">
                  <div class="member-icon" :style="{ backgroundColor: color(j) }">
                    {{ i.inc_name.substr(0, 1) }}
                  </div>
                  <div class="member-centent">
                    <div class="member-company">{{ i.inc_name }}</div>
                    <div class="member-box">
                      <div class="member-box-title">会员姓名</div>
                      <div class="member-name">{{ i.chinese_name }}</div>
                    </div>
                    <div class="member-box">
                      <div class="member-box-title">职位</div>
                      <div class="member-name">{{ i.inc_position }}</div>
                    </div>
                    <div class="member-box">
                      <div class="member-box-title">经营范围</div>
                      <div class="member-introduce">
                        <div class="member-introduce-text" :style="{
                          display: open == i.id ? 'block' : '-webkit-box',
                        }">
                          {{ i.business_range }}
                        </div>
                        <div class="open" @click="onOpen(i.id)">
                          {{ open != i.id ? "展开全部" : "收起" }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchmembers } from "@/api/home.js";
export default {
  name: "SurveyMember",
  data() {
    return {
      value: "",
      open: 0,
      colors: ["#ea8e4b", "#8fbaff", "#f1c83e"],
      members: [],
    };
  },
  mounted() {
    this.getMember();
  },
  methods: {
    async getMember(value) {
      var getmemberlist =
        value == undefined ? fetchmembers() : fetchmembers({ key: this.value });
      const { result } = await getmemberlist;
      result.forEach((item) => {
        item.child.forEach((x) => {
          if (x.member_list.length == 0) {
            x.watch = false;
          } else {
            x.watch = true;
          }
        });
      });
      result.forEach((item) => {
        var exist = item.child.findIndex(item => {
          return item.watch == true;
        });
        if (exist != -1) {
          item.watch = false;
        } else {
          item.watch = true;
        }
      });
      this.members = result;
      console.log(this.members);
    },
    onSearch() {
      this.getMember(this.value);
    },
    onOpen(id) {
      this.open = id == this.open ? 0 : id;
    },
    color(index) {
      if (index < 3) {
        return this.colors[index];
      } else {
        return this.colors[index % 3];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.survey-member {
  padding: 6vw 3vw;
}

.contact-search {
  .van-search {
    padding: 0;

    .van-search__content {
      border: 1px solid #dfdfdf;
      border-radius: 2vw;
    }

    .search {
      font-size: 4vw;
      color: #007069;
    }
  }
}

.member-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .member-list-one {
    width: 100%;
    padding: 8vw 0 0;

    .member-one-title {
      width: 27vw;
      height: 8vw;
      font-size: 5vw;
      color: #fff;
      background-image: linear-gradient(90deg, #2dcc74 0%, #9be664 100%);
      border-radius: 4vw 4vw 4vw 1vw;
      text-align: center;
      line-height: 8vw;
    }

    .member-industry {
      .member-industry-name {
        font-size: 5vw;
        color: #333;
        font-weight: bold;
        display: flex;
        align-items: center;
        margin-top: 4vw;

        .img {
          width: 5vw;
          height: 5vw;
          background: url(../../../../src/assets/images/diantu.png) no-repeat;
          background-size: 100%;
          display: block;
          margin-right: 1.5vw;
        }
      }

      .member {
        width: 100%;

        .member-one {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 4vw 0 0;

          .member-icon {
            width: 13vw;
            height: 13vw;
            // background-color: #ea8e4b;
            border-radius: 2vw;
            margin-right: 3vw;
            font-size: 7vw;
            color: #fff;
            line-height: 13vw;
            text-align: center;
          }

          .member-centent {
            width: calc(100% - 16vw);
            padding-bottom: 3vw;
            border-bottom: 1px solid #ebebeb;

            .member-company {
              font-size: 4vw;
              color: #333;
              margin-bottom: 4vw;
            }

            .member-box {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              margin-bottom: 2vw;

              .member-box-title {
                width: 16vw;
                height: 5vw;
                background-color: #f3f5f7;
                font-size: 3vw;
                color: #666666;
                text-align: center;
                line-height: 5vw;
                margin-right: 2vw;
              }

              .member-name {
                font-size: 3vw;
                color: #333;
              }

              .member-introduce {
                width: calc(100% - 18vw);
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .member-introduce-text {
                  font-size: 3vw;
                  color: #333;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  margin-bottom: 3vw;
                }

                .open {
                  font-size: 3vw;
                  color: #6a7fa5;
                }
              }
            }
          }
        }
      }
    }
  }
}</style>
