<template>
  <div class="components-content">
      <div id="html-content" v-html="content"></div>
      <div><span style="color: red;">注：</span>下载入会申请表请到PC端网页下载！</div>
  </div>
</template>

<script>
import { fetchAbout } from "@/api/home.js";
export default {
  name: "SurveyTable",
  data() {
    return {
      content: ""
    };
  },
  mounted() {
    this.getAbout();
  },
  methods: {
    async getAbout() {
      const { result } = await fetchAbout();
      this.content = result.introduce;
    }
  }
};
</script>

<style lang="less" scoped>
.survey-table {
  padding: 6vw 3vw;
}
</style>

<style lang="less">

</style>