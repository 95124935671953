<template>
  <div class="survey">
    <top-head></top-head>
    <menu-list :menuList="menuList" @changeActive="changeActive"></menu-list>
    <component :is="active.component"></component>
    <bottom-bar></bottom-bar>
  </div>
</template>

<script>
import TopHead from "@/components/TopHead";
import SurveyAbout from "@/views/survey/components/SurveyAbout";
import SurveyCharter from "@/views/survey/components/SurveyCharter";
import SurveySchema from "@/views/survey/components/SurveySchema";
import SurveyMember from "@/views/survey/components/SurveyMember"
import SurveyTable from "@/views/survey/components/SurveyTable";
import SurveyContact from "@/views/survey/components/SurveyContact";
import BottomBar from "../../components/BottomBar.vue";
import MenuList from "@/components/MenuList";
export default {
  name: "survey",
  data() {
    return {
      active: {},
      show: false,
      menuList: [
        {
          title: "青企简介",
          component: "SurveyAbout"
        },
        {
          title: "青企章程",
          component: "SurveyCharter"
        },
        {
          title: "组织架构",
          component: "SurveySchema"
        },
        {
          title:"会员页面",
          component: "SurveyMember"
        },
        {
          title: "入会表格",
          component: "SurveyTable"
        },
        {
          title: "联系我们",
          component: "SurveyContact"
        }
      ]
    };
  },
  methods: {
    changeActive(active) {
      this.active = active;
      this.$router.push({
        name: "Survey",
        params: {
          component: active.component
        }
      });
      this;
    }
  },
  components: {
    TopHead,
    SurveyAbout,
    SurveyCharter,
    SurveySchema,
    SurveyMember,
    SurveyTable,
    SurveyContact,
    BottomBar,
    MenuList
  }
};
</script>

<style lang="less" scoped>
.content-box {
  position: relative;
}
.content {
  position: absolute;
}
.active-menu {
  box-sizing: border-box;
  padding: 0 3vw;
  height: 13vw;
  font-size: 4vw;
  color: #404040;
  background-color: #f4f4f4;
  border-bottom: 1px solid #d7d7d9;
  .arrow-icon {
    transition: all 0.3s ease-in-out;
    &.active {
      transform: rotate(-180deg);
    }
  }
}
.menu-list {
  width: 100%;
  position: absolute;
  z-index: 5;
  padding: 0 3vw;
  background-color: #f4f4f4;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
  &.active {
    max-height: 88vw;
  }
  .menu-item {
    margin: 5vw 0 10vw 0;
    font-family: PingFang-SC-Medium;
    font-size: 4vw;
    color: #404040;
    &.active-color {
      color: #007069;
    }
  }
}
</style>
