<template>
  <div class="components-content">
    <div class="contact-title">
      顺德青年企业家协会
    </div>
    <div class="contact">电话: {{ info.contacter_phone }}</div>
    <div class="contact">邮件: {{ info.email }}</div>
    <div class="contact">地址: {{ info.address }}</div>
    <div class="qr-code-box fcc">
      <img class="qr-img" :src="info.contact_qrcode" alt="" />
      <div class="qr-name">顺德青企协官微</div>
    </div>
    <div class="map" id="container"></div>
  </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import { fetchAbout } from "@/api/home.js";
export default {
  name: "SurveyContact",
  data() {
    return {
      info: {}
    };
  },
  methods: {
    async getInfo() {
      const { result } = await fetchAbout();
      this.info = result;
    }
  },
  mounted() {
    this.getInfo();
    AMapLoader.load({
      key: "3c4c412e43270789e5c3d78c1f79aaba", // 申请好的Web端开发者Key，首次调用 load 时必填
      version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: []
      //插件列表
    })
      .then(AMap => {
        let map = new AMap.Map("container", {
          // container为容器的id
          zoom: 15, //初始化地图层级
          center: [113.128301, 23.021165] //初始化地图中心点
        });
        let trafficLayer = new AMap.TileLayer.Traffic({
          zIndex: 2
        });
        map.add(trafficLayer); //添加图层到地图
      })
      .catch(e => {
        console.log(e);
      });
  }
};
</script>

<style lang="less" scoped>
.survey-contact {
  padding: 6vw 3vw;
}
.contact-title {
  font-family: PingFang-SC-Bold;
  font-size: 4vw;
  color: #404040;
}
.contact {
  margin: 3vw 0;
  font-family: PingFang-SC-Medium;
  font-size: 4vw;
  color: #404040;
}
.qr-img {
  width: 47vw;
  height: 47vw;
}
.qr-name {
  margin-top: 3vw;
  font-size: 3vw;
  color: #404040;
}
.map {
  margin-top: 8vw;
  width: 100%;
  height: 62vw;
}
</style>
